<template>
  <div
    class="layout-container mb-48 md:mb-64"
    :class="{
      'px-0 sm:px-24 md:px-56': model.useMobileSlider
    }"
  >
    <div
      v-if="model.header || model.blockLink || isEpiEdit"
      class="sm:flex justify-between mb-16"
      :class="{
        'px-24 sm:px-0': model.useMobileSlider
      }"
    >
      <h2
        v-if="model.header"
        v-epi-edit="'Title'"
        class="block mb-8 sm:mb-0 text-lg sm:text-xl leading-base sm:leading-lg"
      >
        {{ model.header }}
      </h2>
      <div
        v-else-if="isEpiEdit"
        v-epi-edit="'Title'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        + Add title
      </div>
      <GlobalsLinkHelper
        v-if="model.blockLink"
        :to="model.blockLink.href"
        class="arrow-link self-end text-xs sm:text-sm sm:ml-auto"
      >
        {{ model.blockLink.text }}
      </GlobalsLinkHelper>
      <div
        v-else-if="isEpiEdit"
        v-epi-edit="'linkText'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        + Add link
      </div>
      <div
        v-if="isEpiEdit"
        v-epi-edit="'linkUrl'"
        class="absolute bottom-[-50px] right-0 btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        Edit link
      </div>
    </div>
    <SideScroll v-if="model.useMobileSlider && isMobile" class="no-scrollbar">
      <div class="flex">
        <GlobalsLinkHelper
          v-for="(brand, index) in brands"
          :key="index"
          :to="brand.url"
          class="aspect-[4/2.5] rounded-md border border-grey400 flex items-center justify-center flex-shrink-0 mr-12 w-1/4 first:ml-24 hover:border-black animate-all"
        >
          <BrandListingBlockItem
            :brand="brand"
          />
        </GlobalsLinkHelper>
        <div class="w-24 flex-shrink-0" />
      </div>
    </SideScroll>
    <div
      v-else
      class="grid grid-cols-3 gap-12 sm:grid-cols-6 lg:grid-cols-12 mb-24"
    >
      <GlobalsLinkHelper
        v-for="(brand, index) in brands"
        :key="index"
        :to="brand.url"
        class="aspect-[4/2.5] rounded-md border border-grey400 flex items-center justify-center hover:border-black animate-all"
      >
        <BrandListingBlockItem
          :brand="brand"
        />
      </GlobalsLinkHelper>
    </div>
  </div>
</template>
  
<script setup lang="ts">
import type { BrandListingBlock } from '~/content-types';
import SideScroll from '~/components/globals/SideScroll.vue';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import { usePageContentStore } from '~/store/pageContent';
import type { IBrandListingBlockData } from '~/api-types';

const { isMobile } = storeToRefs(useUiStore());
const pageStore = usePageContentStore();
const { isEpiEdit } = storeToRefs(pageStore);
  
const props = defineProps<{
    model: BrandListingBlock
  }>();

const brands = computed(() => {
  const brandListingBlockData = props.model.pageData as IBrandListingBlockData;
  return brandListingBlockData.brands;
});

</script>
